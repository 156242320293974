<template>
    <div style="text-align: left;background-color: #FFFFFF">
            <div class="pageCenter realNameForm-container">
                <template v-if="realNameVertifyStatus === 0">
                    <el-form :model="realNameForm" :rules="realNameFormRules" ref="realNameForm" label-width="90px">
                          <el-form-item>
                          </el-form-item>
                          <el-form-item label="姓名" prop="name">
                              <el-input v-model="realNameForm.name" autocomplete="off" placeholder="请填写真实姓名" maxlength="50"></el-input>
                          </el-form-item>
                          <el-form-item label="身份证号码" prop="idCardNumber">
                              <el-input v-model="realNameForm.idCardNumber" minlength="15" maxlength="18" autocomplete="off" placeholder="身份证号码"></el-input>
                          </el-form-item>
                          <el-form-item prop="photo">
                              <div style="width: 100%;padding: 0 0 25px 0;">
                                  <div style="color: gray;">微信扫码上传肖像照片(请确保面部清晰可见)</div>
                                  <div v-show="!realNameForm.photo">
                                      <div ref="photoQrCodeUrl"></div>
                                  </div>
                                  <div v-if="realNameForm.photo">
                                      <div style="text-align: center;width: 146px;">
                                          <img :src="this.$oucy.ossUrl+realNameForm.photo" style="width: 146px;height: 146px;border-radius: 3px;">
                                      </div>
                                      <div style="color: #cccccc"><i class="el-icon-success" style="color: #1aad19;font-size: 20px;"></i>操作成功！ 你也可以 <el-button @click="reScanHandle" type="text">重新扫码上传</el-button>。</div>
                                  </div>
                              </div>
                          </el-form-item>
                          <el-form-item>
                              <el-button @click="keywordFiltr('submitRealnameApply')" type="primary">申请认证</el-button>
                              <el-button @click="$oucy.replace('/acc/index', null)">取  消</el-button>
                          </el-form-item>
                      </el-form>
                </template>
                <template v-if="realNameVertifyStatus === 1">
                    <div style="text-align: center">
                        <h1>个人实名认证</h1>
                        <div style="padding: 100px 0 0 0;">
                            <img src="../../../assets/realNameSuccess.jpg">
                        </div>
                        <div style="color: #1aad19;">认证成功</div>
                        <div style="line-height: 50px;">恭喜，您的认证已通过!</div>
                        <div style="padding: 100px 0 0 0">
                            <el-button @click="$oucy.replace('/acc', null)">返回</el-button>
                        </div>
                    </div>
                </template>
            </div>
    </div>
</template>

<script>
    import oucy from "../../../util/oucyUtil";
    import QRCode from "qrcodejs2";
    import {userAuth} from "@/service"
    export default {
        name: "RealnameVertify",
        data(){
            var isName = (rule, value, callback) => {
               if (!value) {
                 return callback(new Error('姓名不能为空'));
               }else{
                if(!oucy.isName(value)){
                 return callback(new Error('姓名 2-4 个中文'));
                }
               }
             };
            var isCardNo = (rule, value, callback) => {
               if (!value) {
                 return callback(new Error('身份证号码不能为空'));
               }else{
                if(!oucy.isCardNo(value)){
                 return callback(new Error('身份证号码应是15位数字或者17位为数字加一位校验位'));
                }
               }
             };
            return{
                realNameVertifyStatus:null,//null:未知 0未通过 1已通过
                realNameForm:{
                    name:null,
                    idCardNumber:null,
                    photo:null
                },
                realNameFormRules:{
                    name: [
                        {required: true, message: '请填写真实姓名', trigger: 'blur'},
                        { validator: isName, trigger: 'blur' }
                    ],
                    idCardNumber: [
                        {required: true, message: '请填写身份证号码', trigger: 'blur'},
                        { validator: isCardNo, trigger: 'blur' }
                    ],
                    photo: [
                        {required: true, message: '请上传照片', trigger: 'blur'},
                    ]
                },
                qrCode:null
            }
        },
        mounted() {
            const me = this;

            this.queryRealNameVertifyStatus().then(res=>{
                me.creatQrCode();
                me.eventBus.$off(me.$oucy.msgKeys.WECHAT_REALNAME_AVATA_UPLOADD).$on(me.$oucy.msgKeys.WECHAT_REALNAME_AVATA_UPLOADD,(res)=>{
                    console.log('扫码事件：');
                    console.log(res);
                    me.realNameForm.photo = res;
                })
            });
        },
        methods:{
            keywordFiltr(fn){
                this.$oucy.keywordFiltr(this.realNameForm).then(res=>{
                   this[fn]() 
                },err=>{})
            },
            creatQrCode() {
                this.queryUserRealAvataScanAddress()
                return
                const me = this;
                let qrCode = new QRCode(this.$refs.photoQrCodeUrl, {
                    text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfRealNamePhoto+'?p='+me.$oucy.getUUID()+ "&operationOriginPlatform=0&operationUv="+me.$oucy.getOperationUv(),
                    width: 146,
                    height: 146,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            /**
             * 重新扫码
             */
            reScanHandle: function () {
                const me = this;
                me.realNameForm.photo = null;
            },
            queryRealNameVertifyStatus: function(){
                const me = this;
                return new Promise((resolve, reject) => {
                    oucy.postRequest('/client/user/realnameauthentication/isRealNameAuthentication',{}).then(res=>{
                        me.realNameVertifyStatus = res;
                        resolve(res);
                    }).catch(err=>{
                        reject(err);
                    });
                });

            },
            submitRealnameApply: function () {
                const me = this;
                me.$refs["realNameForm"].validate((valid) => {
                    if (valid) {
                        oucy.postRequest('/client/user/realnameauthentication/addRealNameAuthentication',{
                            authenticationAvata:me.realNameForm.photo,
                            authenticationIdCard:me.realNameForm.idCardNumber,
                            authenticationName:me.realNameForm.name
                        }).then(res=>{
                            return  me.queryRealNameVertifyStatus();
                        });
                    }
                });
            },
            queryUserRealAvataScanAddress(){
                userAuth.queryUserRealAvataScanAddress({
                    p:this.$oucy.getUUID(),
                    operationOriginPlatform:0,
                    operationUv:this.$oucy.getOperationUv()
                }).then(res=>{
                    if(!this.qrCode){
                        this.qrCode = new QRCode(this.$refs.photoQrCodeUrl, {
                            // text: text,
                            // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                            width: 146,
                            height: 146,
                            colorDark: '#000000',
                            colorLight: '#ffffff',
                            correctLevel: QRCode.CorrectLevel.H
                        })
                    }
                    this.qrCode && this.qrCode.clear();
                    this.qrCode.makeCode(res)
                })
            },
        }
    }
</script>

<style scoped>
.realNameForm-container{
    width: 400px;
    min-height: 600px;
    padding:50px;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 146px;
    height: 156px;
    line-height: 156px;
    text-align: center;
}
.avatar {
    width: 146px;
    height: 156px;
    display: block;
}
.avatar-uploader >>> .el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 auto;
    display: inline-block;
}
.avatar-uploader >>> .el-upload:hover {
    border-color: #409EFF;
}
/deep/ .el-form-item label:after {
  content: " ";
}

/deep/ .el-form-item__label {
  text-align-last: justify
}
/* 这里去除必选字段的*,这个符号会造成一定影响 */
/deep/ .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
</style>
